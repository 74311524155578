// 推销商品业绩概况
export const recommentGoodsColumns = [
  {
    type: "",
    label: "推销人",
    prop: "nickName",
  },
  {
    type: "",
    label: "总推销订单数",
    prop: "performanceNumTotal",
  },
  {
    type: "",
    label: "有效订单数",
    prop: "performanceNum",
  },
  {
    type: "",
    label: "退款订单数",
    prop: "refundPerformanceNum",
  },
  {
    type: "",
    label: "订单实付总金额",
    prop: "paymentAmount",
    dataType: "slot",
    slot: "paymentAmountSlot",
  },
  {
    type: "",
    label: "订单退款总金额",
    prop: "refundAmount",
    dataType: "slot",
    slot: "refundAmountSlot",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
export const recommentGoodsDetailColumns = [
  {
    type: "",
    label: "下单时间",
    prop: "addDt",
  },
  {
    type: "",
    label: "派送完成时间",
    prop: "formEndTime",
  },
  {
    type: "",
    label: "用户",
    prop: "",
    dataType: "slot",
    slot: "userInfoSlot",
  },
  {
    type: "",
    label: "商品",
    prop: "goodsInfo",
  },
  {
    type: "",
    label: "订单位置",
    prop: "position",
  },
  {
    type: "",
    label: "支付方式",
    prop: "paymentInfo",
  },

  {
    type: "",
    label: "已退款金额",
    prop: "",
    dataType: "slot",
    slot: "refundPriceSlot",
  },
  {
    type: "",
    label: "实付金额",
    prop: "",
    dataType: "slot",
    slot: "performanceAmountSlot",
  },
];
export const rechargeColumns = [
  {
    type: "",
    label: "推销人",
    prop: "nickName",
  },
  {
    type: "",
    label: "推销充值总金额",
    prop: "",
    dataType: "slot",
    slot: "performanceAmountSlot",
  },
  {
    type: "",
    label: "推销充值订单数",
    prop: "performanceNum",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
export const rechargeDetailColumns = [
  {
    type: "",
    label: "交易时间",
    prop: "paymentDate",
  },
  {
    type: "",
    label: "充值人",
    prop: "",
    dataType: "slot",
    slot: "userSlot",
  },
  {
    type: "",
    label: "充值套餐",
    prop: "packageName",
  },
  {
    type: "",
    label: "充值额",
    prop: "",
    dataType: "slot",
    slot: "packageAmountSlot",
  },
  {
    type: "",
    label: "套餐归属",
    prop: "packageBelongTo",
  },
  {
    type: "",
    label: "交易门店",
    prop: "shopName",
  },
];
// 组织架构------------------------
// 岗位管理
export const postColumns = [
  {
    type: "",
    label: "岗位名称",
    prop: "roleName",
  },
  {
    type: "",
    label: "所属商户",
    prop: "merchantNames",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
// 员工管理
export const staffColumns = [
  {
    type: "",
    label: "姓名",
    prop: "userName",
  },
  {
    type: "",
    label: "手机号（账号）",
    prop: "mobile",
  },
  {
    type: "",
    label: "性别",
    prop: "",
    dataType: "slot",
    slot: "sexSlot",
  },
  {
    type: "",
    label: "归属部门",
    prop: "deptNames",
  },
  {
    type: "",
    label: "岗位",
    prop: "roleName",
  },
  {
    type: "",
    label: "已关联企业",
    prop: "",
    dataType: "slot",
    slot: "enterpriseSlot",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
// 审批业务管理------------------------
// 流程设置
export const settingColumns = [
  {
    type: "",
    label: "审批流名称",
    prop: "processName",
  },
  {
    type: "",
    label: "审批流分组",
    prop: "processGroupName",
  },
  {
    type: "",
    label: "审批流类型",
    prop: "processTypeName",
 
  },
  {
    type: "",
    label: "创建时间",
    prop: "addDt",
  },
  {
    type: "",
    label: "状态",
    prop: "",
    dataType: "slot",
    slot: "stop",
  },
  {
    type: "",
    label: "流程说明",
    prop: "",
    dataType: "slot",
    slot: "remark",
    align: "left",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
// 工单类型管理
export const workTypeColumns = [
  {
    type: "",
    label: "工单类型名称",
    prop: "workSheetName",
  },
  {
    type: "",
    label: "归属类",
    prop: "attributionTypeName",
  },
  {
    type: "",
    label: "创建时间",
    prop: "addDt",
  },
  {
    type: "",
    label: "创建人",
    prop: "optUser",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
// 工单模板管理
export const workModelColumns = [
  {
    type: "",
    label: "工单模板名称",
    prop: "workSheetName",
  },
  {
    type: "",
    label: "工单类型",
    prop: "workSheetTypeName",
  },
  {
    type: "",
    label: "关联审批流程",
    prop: "processName",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    width: "500",
    dataType: "slot",
    slot: "operation",
  },
];
// 模板参数
export const modelParamColumns = [
  {
    type: "",
    label: "参数名称",
    prop: "paramName",
  },
  {
    type: "",
    label: "参数类型",
    prop: "",
    dataType: "slot",
    slot: "paramTypeSlot",
  },
  {
    type: "",
    label: "属性类型",
    prop: "",
    dataType: "slot",
    slot: "inputTypeSlot",
  },
  {
    type: "",
    label: "是否必填",
    prop: "",
    dataType: "slot",
    slot: "requiredSlot",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
]
// 工单中心
export const workCenterColumns = [
  {
    type: "",
    label: "工单编号",
    prop: "projectCode",
  },
  {
    type: "",
    label: "工单类型",
    prop: "workProjectTypeName",
  },
  {
    type: "",
    label: "工单归属门店",
    prop: "projectName",
  },
  {
    type: "",
    label: "发起人",
    prop: "applyUserName",
  },
  {
    type: "",
    label: "审批摘要",
    prop: "applyUserName",
  },
  {
    type: "",
    label: "发起人部门",
    prop: "mobile",
  },
  {
    type: "",
    label: "工单名称",
    prop: "projectInstanceName",
  },
  {
    type: "",
    label: "发起时间",
    prop: "applyStartTime",
  },
  {
    type: "",
    label: "完成时间",
    prop: "applyStartTime",
  },
  {
    type: "",
    label: "当前审批/办理人",
    prop: "approveEmpName",
  },
  {
    type: "",
    label: "审批状态",
    prop: "",
    dataType: "slot",
    slot: "approveStatusNameSlot",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
// 工单交接
export const workHandoverColumns = [
  {
    type: "",
    label: "原接管人",
    prop: "orgUserName",
  },
  {
    type: "",
    label: "现接管人",
    prop: "newUserName",
  },
  {
    type: "",
    label: "交接项目",
    prop: "instanceNames",
  },
  {
    type: "",
    label: "交接时间",
    prop: "addDt",
  },
  {
    type: "",
    label: "交接备注",
    prop: "remark",
  },
]
// 原接管人工单
export const orgUserWorkSheetColumns= [
  {
    type: "",
    label: "工单编号",
    prop: "sheetCode",
  },
  {
    type: "",
    label: "工单类型",
    prop: "workSheetTypeName",
  },
  {
    type: "",
    label: "工单归属门店",
    prop: "projectName",
  },
  {
    type: "",
    label: "发起人",
    prop: "applyUserName",
  },
  {
    type: "",
    label: "审批摘要",
    prop: "",
    dataType: "slot",
    slot: "remarkSlot",
  },
  {
    type: "",
    label: "发起人部门",
    prop: "applyUserDeptName",
  },
  {
    type: "",
    label: "工单名称",
    prop: "sheetInstanceName",
  },]